import React, {useState} from "react";
import {Link, useLocation} from "react-router-dom";
import style from './Menu.module.scss';
import logo from '../../assets/julia-logo.svg'
import StyleHelper from "../../services/Style.helper";
import {useTranslation} from "react-i18next";
import Image from "../Image/Image";
import RoutesEnum from "../../enums/Routes.enum";

const Menu = () => {

    const [burgerMenuEnabled, setBurgerMenuEnabled] = useState(false);
    let location = useLocation();
    const {t} = useTranslation();

    if ([RoutesEnum.MysteriousElixir.toString()].includes(location.pathname)) {
        return (<div></div>);
    }

    return (
        <div className={style.menu}>
            <span className={style.menu__title}>
                <Link className={StyleHelper.concatClass([
                    style.menu__items__logo,
                ])} to={RoutesEnum.Home}>
                    <Image src={logo} alt={t('fullName')} withEffect={false}/>
                </Link>
            </span>
            <button className={StyleHelper.concatClass([
                style.menu__burger,
                burgerMenuEnabled ? style.menu__burger__active : ''
            ])} onClick={() => setBurgerMenuEnabled(!burgerMenuEnabled)}></button>
            <div className={StyleHelper.concatClass([
                style.menu__items,
                burgerMenuEnabled ? style.menu__items__active : ''
            ])}>
                <Link className={StyleHelper.concatClass([
                    style.menu__items__item,
                    burgerMenuEnabled ? style.menu__items__item__active : ''
                ])} to={RoutesEnum.Projects}>{t('menu.projects')}</Link>
                <Link className={StyleHelper.concatClass([
                    style.menu__items__item,
                    burgerMenuEnabled ? style.menu__items__item__active : ''
                ])} to={RoutesEnum.About}>{t('menu.about')}</Link>
                <Link className={StyleHelper.concatClass([
                    style.menu__items__item,
                    burgerMenuEnabled ? style.menu__items__item__active : ''
                ])} to={RoutesEnum.Contact}>{t('menu.contact')}</Link>
            </div>
        </div>
    );
}

export default Menu;
